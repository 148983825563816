<template>
  <div style="text-align: justify">
    <p class="mb-4">
      The following table provides data (pH vs Volume of based added) for the titration of a weak
      acid with a strong base. Using these data, construct a graph of the titration curve. For this
      plot, use the longer side of the graph paper for the pH axis, and the shorter side for the
      volume axis, using the full sheet of graph paper.
    </p>

    <v-simple-table style="margin-left: 15px; margin-bottom: 20px; width: 200px" dense>
      <thead>
        <tr>
          <th style="text-align: left">
            <stemble-latex content="$\textbf{Vol (mL)}$" />
          </th>
          <th style="text-align: center">
            <stemble-latex content="$\textbf{pH}$" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(volumeBValue, i) in volumeBValues" :key="volumeBValue">
          <td style="text-align: left">
            {{ volumeBValues[i].toFixed(2) }}
          </td>
          <td style="text-align: center">
            {{ pHValues[i].toFixed(2) }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <p class="mb-4">From this graph determine:</p>

    <p class="mb-3">
      a) the volume (to the nearest 0.2 mL) of base used to reach the equivalence point of the
      titration
    </p>

    <calculation-input
      v-model="inputs.Veq"
      :show-scientific-notation="false"
      prepend-text="$\text{V}_\text{eq}:$"
      append-text="$\text{mL}$"
      class="mb-5"
      :disabled="!allowEditing"
    />

    {{ actualPKa }}

    <p class="mb-3">
      b) the <stemble-latex content="$\text{pK}_{\text{a}}$" /> (to the nearest 0.05 pH units) of
      the weak acid
    </p>

    <calculation-input
      v-model="inputs.pKa"
      :show-scientific-notation="false"
      prepend-text="$\text{pK}_\text{a}:$"
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {getPh, weakAcidTitrationH} from '@/tasks/utils/acid-base';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UPEIMonoproticTitrationPreLab',
  components: {CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Veq: null,
        pKa: null,
      },
    };
  },
  computed: {
    volumeA() {
      return this.taskState.getValueBySymbol('volumeA').content.toFloat();
    },
    actualPKa() {
      return this.taskState.getValueBySymbol('actualPKa').content.toFloat();
    },
    Ka() {
      return Math.pow(10, -this.actualPKa);
    },
    molarityA() {
      return this.taskState.getValueBySymbol('molarityA').content.toFloat();
    },
    molarityB() {
      return this.taskState.getValueBySymbol('molarityB').content.toFloat();
    },
    volumeBValues() {
      // Adjust these and maybe multiply i by something if you want non-integer volumes
      const start = 0;
      const stop = 37;
      return new Array(stop - start).fill(0).map((_, i) => i * 0.5);
    },
    concentrationHValues() {
      return this.volumeBValues.map((vol) =>
        weakAcidTitrationH(vol, this.volumeA, this.molarityB, this.molarityA, this.Ka, 1e-14)
      );
    },
    pHValues() {
      return this.concentrationHValues.map(getPh);
    },
  },
};
</script>
